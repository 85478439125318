import {
  computed,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onActivated
} from 'vue'
import { useRouter } from 'vue-router'

const INIT_PAGE = 0
const INIT_LIMIT = 20
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    inputWord: '',
    searchWord: '',
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    params: computed({
      get: () => {
        return {
          startNum: state.list.length + 1,
          endNum: (state.page + 1) * state.limit,
          searchWord: state.searchWord
        }
      }
    }),
    listScrollTop: 0,
    categoryNames: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id')
  })
  const fnListFunction = async () => {
    const res = await proxy.$MyPageSvc.postPenalty(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(res.list)
    }
  }
  const fnSearch = () => {
    state.searchWord = state.inputWord
    fnReset()
    state.initCnt++
  }
  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }

  const fnView = async item => {
    const pageCon = document.querySelector('#page_con')
    if (pageCon) {
      state.listScrollTop = pageCon.scrollTop
    }
    await router.push({
      path: '/my-page/joined-party/view',
      query: { partyNo: item.partyNo }
    })
  }
  /** watch **/
  watch(
    () => state.sort,
    async () => {
      await fnSearch()
    }
  )
  onActivated(async () => {
    if (!proxy.$Util.isEmpty(state.listScrollTop) && state.listScrollTop > 0) {
      document.querySelector('#page_con').scrollTop = state.listScrollTop
      state.listScrollTop = 0
    } else {
      fnSearch()
    }
  })

  //  wizzney-group/running#596 우선 penalty 강제 접근 시 block 처리
  router.replace({ path: '/my-page/home' })

  return {
    ...toRefs(state),
    fnListFunction,
    fnSearch,
    fnView
  }
}
