<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con" id="page_con">
          <div class="lump bo_zero">
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="검색어를 입력해 주세요."
                v-model="inputWord"
                @keyup.enter="fnSearch"
              />
              <button class="btn_search" @click="fnSearch">검색</button>
            </label>
          </div>
          <div class="notice_list_wrap">
            <div class="list_top">
              <div class="left">
                <div class="total">총 {{ totalCnt }}건</div>
              </div>
              <div class="right"></div>
            </div>
            <div class="notice_list" v-if="!$Util.isEmpty(list)">
              <ul class="notice_list_ul">
                <li
                  class="notice_list_li"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <div class="notice_list_ttl">
                    <div class="left">
                      <span>{{ item.contents }}</span>
                    </div>
                    <div class="right">
                      <span class="error">{{ item.type }}</span>
                    </div>
                  </div>
                  <div class="notice_list_con">
                    <div class="left">
                      <div class="date">{{ item.regDt }}</div>
                    </div>
                    <div class="right"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>
                  검색된 결과가 없습니다.
                </div>
              </div>
            </div>
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'my-page-penalty-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
